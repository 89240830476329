<template>
<div class="text-center" v-if="resolutionScreen < 500">
    <v-snackbar v-model="snackbar" :timeout="timeout" :top="resolutionScreen < 500" :max-height="resolutionScreen">
      <v-card color="transparent" class="pa-0">
        <v-card-text class="pa-0">
      <v-list color="transparent" dark v-if="count_loadingfile === undefined && count_allfile === undefined">
        <v-list-item>
          <v-list-item-content>
            {{ $t("myinboxPage.dowload.dowloading") }}
              <v-list-item-title  v-if="namedowload === '' || namedowload === null || namedowload === undefined">{{date}}</v-list-item-title> 
              <v-list-item-title v-else-if="date === '' || date === null || date === undefined">{{namedowload}}</v-list-item-title> 
          </v-list-item-content>   
          <v-list-item-action>      
          <v-icon v-if="percent === 100" color="green">mdi-check-bold</v-icon>
          <div v-else class="text-center pt-1"><a style="color:white;">{{percent + '%'}}</a></div>  
          </v-list-item-action>     
        </v-list-item>
      
      </v-list>
      <v-list color="transparent" dark v-else>
        <v-list-item>
          <v-list-item-content v-if="percent === 100">  
            {{$t('myinboxPage.dowload.dowloadsuccess')}}  
          <v-list-item-title  v-if="namedowload === '' || namedowload === null || namedowload === undefined">{{date}}</v-list-item-title> 
          <v-list-item-title v-else-if="date === '' || date === null || date === undefined">{{namedowload}}</v-list-item-title>                  
          </v-list-item-content>
          <v-list-item-content v-else-if="percent === 0">  
            <!-- กำลังเตรียมการดาวน์โหลด กรุณารอสักครู่...  -->
            {{$t('myinboxPage.dowload.downloadpreparing')}}
          <v-list-item-title  v-if="namedowload === '' || namedowload === null || namedowload === undefined">{{date}}</v-list-item-title> 
          <v-list-item-title v-else-if="date === '' || date === null || date === undefined">{{namedowload}}</v-list-item-title>                  
          </v-list-item-content>
          <v-list-item-content v-else>
            <div v-if="showtotalfileonly">
              {{$t('myinboxPage.dowload.dowloading')}}   {{downloadedbycompany === true ? "" : `(${count_allfile} ${count_allfile > 1 ? $t('files') : $t('file') })` }}
            </div>
            <div v-else>
              {{$t('myinboxPage.dowload.dowloading')}}   {{downloadedbycompany === true ? "" : `(${count_loadingfile}/${count_allfile} ${count_allfile > 1 ? $t('files') : $t('file') })` }}
            </div>
              <!-- {{statusloading}}   ( {{count_loadingfile}}/{{count_allfile}})   -->
            <v-list-item-title  v-if="namedowload === '' || namedowload === null || namedowload === undefined">{{date}}</v-list-item-title> 
            <v-list-item-title v-else-if="date === '' || date === null || date === undefined">{{namedowload}}</v-list-item-title>                  
            </v-list-item-content> 
          <v-list-item-action>      
          <v-icon v-if="percent === 100" color="green">mdi-check-bold</v-icon>
          <div v-else class="text-center pt-1"><a style="color:white;">{{percent + '%'}}</a></div>  
          </v-list-item-action>     
        </v-list-item>
      
      </v-list>
      </v-card-text>
        <v-progress-linear v-if="btsdownload === true" indeterminate color="green" height="8"></v-progress-linear>
      </v-card>
    </v-snackbar>
  </div>
  <div class="text-center ma-2" v-else>
      <v-snackbar v-model="snackbar" bottom class="v-snack__content" :timeout="timeout">
      <v-card  color="transparent" class="pa-0">
        <v-list color="transparent" dark v-if="count_loadingfile === undefined && count_allfile === undefined">
          <v-list-item dense style="height:40px; width:400px;">
              <v-list-item-content v-if="percent === 100">  
              {{$t('myinboxPage.dowload.dowloadsuccess')}}  
              <v-list-item-title  v-if="namedowload === '' || namedowload === null || namedowload === undefined">{{date}}</v-list-item-title> 
              <v-list-item-title v-else-if="date === '' || date === null || date === undefined">{{namedowload}}</v-list-item-title>                  
              </v-list-item-content>
            <v-list-item-content v-else>  
              {{$t('myinboxPage.dowload.dowloading')}}
              <v-list-item-title  v-if="namedowload === '' || namedowload === null || namedowload === undefined">{{date}}</v-list-item-title> 
              <v-list-item-title v-else-if="date === '' || date === null || date === undefined">{{namedowload}}</v-list-item-title>                  
              </v-list-item-content>
            
              <v-list-item-action>
              <v-icon v-if="percent === 100 " color="green" >mdi-check-bold</v-icon>
              <div v-else class="text-center pt-1" ><a style="color:white;">{{percent + '%'}}</a></div>
              </v-list-item-action>
          </v-list-item>
        </v-list>
        <v-list color="transparent" dark v-else>
          <v-list-item dense style="height:40px; width:400px;">
              <v-list-item-content v-if="percent === 100">  
              {{$t('myinboxPage.dowload.dowloadsuccess')}}  
              <v-list-item-title  v-if="namedowload === '' || namedowload === null || namedowload === undefined">{{date}}</v-list-item-title> 
              <v-list-item-title v-else-if="date === '' || date === null || date === undefined">{{namedowload}}</v-list-item-title>                  
              </v-list-item-content>
              <v-list-item-content v-else-if="percent === 0">  
              <!-- กำลังเตรียมการดาวน์โหลด กรุณารอสักครู่... -->
              {{$t('myinboxPage.dowload.downloadpreparing')}}
              <v-list-item-title  v-if="namedowload === '' || namedowload === null || namedowload === undefined">{{date}}</v-list-item-title> 
              <v-list-item-title v-else-if="date === '' || date === null || date === undefined">{{namedowload}}</v-list-item-title>                  
              </v-list-item-content>
            <v-list-item-content v-else>  
              <div v-if="showtotalfileonly">
              {{$t('myinboxPage.dowload.dowloading')}}   {{downloadedbycompany === true ? "" : `(${count_allfile} ${count_allfile > 1 ? $t('files') : $t('file') })` }}
              </div>
              <div v-else>
              {{$t('myinboxPage.dowload.dowloading')}}   {{downloadedbycompany === true ? "" : `(${count_loadingfile}/${count_allfile} ${count_allfile > 1 ? $t('files') : $t('file') })` }}
              </div>
              <!-- {{statusloading}}   ( {{count_loadingfile}}/{{count_allfile}}) -->
              <v-list-item-title  v-if="namedowload === '' || namedowload === null || namedowload === undefined">{{date}}</v-list-item-title> 
              <v-list-item-title v-else-if="date === '' || date === null || date === undefined">{{namedowload}}</v-list-item-title>                  
              </v-list-item-content>
            
              <v-list-item-action>
              <v-icon v-if="percent === 100 " color="green" >mdi-check-bold</v-icon>
              <div v-else class="text-center pt-1" ><a style="color:white;">{{percent + '%'}}</a></div>
              </v-list-item-action>
          </v-list-item>
        </v-list>
        <v-progress-linear v-if="btsdownload === true" indeterminate color="green"></v-progress-linear>
      </v-card>
      </v-snackbar>
    </div> 
</template>
<script>
import download from "downloadjs";
import { saveAs } from "file-saver";
import { mapState, mapGetters } from "vuex";
import Swal from "sweetalert2/dist/sweetalert2.js";
import "sweetalert2/src/sweetalert2.scss";
import dialogLoginssoVue from '../optional/dialog-loginsso.vue';

export default {
  data: function(){ 
      return {       
        timeout: 100000000000000,
      }
  },
  props: ["show","percentCompleted","percent","date","namedowload","btsdownload","count_loadingfile","count_allfile","statusloading", "downloadedbycompany","showtotalfileonly"],
  computed: {
      ...mapState(['username', 'authorize','account_active',"color"]),
      ...mapState({processloader: 'loading'}),
      ...mapGetters(['dataUsername', 'dataAuthorize','dataAccountActive', 'dataAccesstoken', 'dataBusinessProfile','dataCitizenProfile', 'dataDepartmentAccessId','dataAccountId']),
    resolutionScreen() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 220;
        case "sm":
          return 400;
        case "md":
          return 500;
        case "lg":
          return 600;
        case "xl":
          return 800;
      }
    },
    snackbar :{
        get() {
            if(this.show === true){
            // console.log("เปิด");
            //  console.log(this.percent);
            //  console.log(this.date);
            console.log(this.count_loadingfile);
            console.log(this.count_allfile);
            }
            return this.show
        },
    },
  },
  
}
</script>
<style>

</style>